<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item>群組保護</b-breadcrumb-item>
            <b-breadcrumb-item active>群組保護設定</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <b-overlay :show="showLoading" rounded="sm">
        <h4 class="mb-2 mb-xl-0 mr-2 font-weight-bold">群組保護設定</h4>

        <div class="row d-flex justify-content-between align-items-center my-3">
          <div class="col-12 col-xl-4 d-flex align-items-center flex-nowrap">
            <h6 class="mb-xl-0 font-weight-bold text-nowrap mr-2">群組</h6>
            <b-form-select
              :options="
                groups.map((x) => {
                  return { text: `${x.branch_code} ${x.name}`, value: x.id };
                })
              "
              v-model="guardGroup"
              @change="fetchSetting"
            >
              <template v-if="groups.length != 1" #first>
                <b-form-select-option :value="null"
                  >-- 請選擇 --</b-form-select-option
                >
              </template>
            </b-form-select>
          </div>
        </div>

        <div v-if="guardGroup" class="p-3">
          <b-form-checkbox
            class="ml-2 mb-2"
            v-model="currentGroup.protect_invite"
          >
            邀請成員保護
          </b-form-checkbox>
          <b-form-checkbox
            class="ml-2 mb-2"
            v-model="currentGroup.protect_avatar"
          >
            群組頭點保護
          </b-form-checkbox>
          <b-form-checkbox
            class="ml-2 mb-2"
            v-model="currentGroup.protect_name"
          >
            群組名稱保護
          </b-form-checkbox>
          <b-form-checkbox
            class="ml-2 mb-2"
            v-model="currentGroup.protect_album"
          >
            相簿保護
          </b-form-checkbox>
        </div>

        <div class="d-flex justify-content-center">
          <b-button class="mr-3" variant="outline-danger">返回 </b-button>
          <b-button
            variant="success"
            @click="setProtectOfGroup"
            :disabled="!guardGroup"
            >儲存</b-button
          >
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import guardApi from "../../../apis/guard";
import { mapState } from "vuex";
import RoleChecker from "@/utils/RoleChecker";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";

export default {
  data() {
    return {
      consts: consts,
      showLoading: true,
      groups: [],
      guardGroup: null,
      currentGroup: {
        protect_invite: false,
        protect_avatar: false,
        protect_name: false,
        protect_album: false,
      },
    };
  },
  mounted() {
    this.fetchGroups();
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
  },
  methods: {
    async fetchGroups() {
      this.showLoading = true;

      try {
        const { data } = await guardApi.getActiveGuardGroups();
        this.groups = data.data;

        if (this.groups.length == 1) {
          this.guardGroup = this.groups[0].id;
          this.fetchSetting();
        }
      } catch (error) {
        console.error(error);
        alert("取得群組資料錯誤");
      }
      this.showLoading = false;
    },

    async fetchSetting() {
      this.showLoading = true;

      try {
        let { data } = await guardApi.getProtectOfGroup(this.guardGroup);
        this.currentGroup = data.data;
      } catch (error) {
        this.currentGroup = {
          protect_invite: false,
          protect_avatar: false,
          protect_name: false,
          protect_album: false,
        };
      }
      this.showLoading = false;
    },

    async setProtectOfGroup() {
      try {
        let response = await guardApi.setProtectOfGroup(this.guardGroup, {
          protect_invite: this.currentGroup["protect_invite"],
          protect_avatar: this.currentGroup["protect_avatar"],
          protect_name: this.currentGroup["protect_name"],
          protect_album: this.currentGroup["protect_album"],
        });

        if (
          response.status &&
          (response.status === 200 || response.status === 201)
        ) {
          this.$swal.fire({
            title: "更新成功",
            type: "success",
          });
        } else {
          alert("更新群組保護設定錯誤");
        }
      } catch (error) {
        alert("更新群組保護設定錯誤");
      }
    },

    statusEnum(status) {
      if (status === 0) {
        return "danger";
      }
      if (status === 1) {
        return "success";
      }
      if (status === 2) {
        return "dark";
      }
    },
    checkRole(roles) {
      const checker = new RoleChecker();
      return checker.check(roles);
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
  },
};
</script>

<style>
.modal-backdrop {
  opacity: 0.5;
}
</style>
